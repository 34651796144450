﻿import React, { useState, useEffect } from "react";

const Timer = ({ startTime }) => {
    const [time, setTime] = useState(startTime);
    const hasTimerEnded = time <= 0

    useEffect(() => {
      
        if (hasTimerEnded) return

        const id = setInterval(() => setTime(time => time - 1), 1000)
        
        return () => clearInterval(id)
    }, [hasTimerEnded]);

    
    function timeString(secondsToEnd) {
        const SECONDS_IN_A_DAY = 86000;

        if (secondsToEnd > SECONDS_IN_A_DAY) {
            const d = Math.floor(secondsToEnd / SECONDS_IN_A_DAY);
            const dayText = d > 1 ? " days" : " day";
            return (
                <div>
                    <span className="timer">{d}</span>
                    <span className="text">{dayText}</span>
                </div>
            );
        } else {
            const hours = Math.floor(secondsToEnd / 3600);
            const hasHours = hours > 0;
            const hoursString = hours > 9 ? hours : `0${hours}`;
            const hourText = hours > 1 ? " hours " : " hour ";

            secondsToEnd %= 3600;

            const minutes = Math.floor(secondsToEnd / 60);
            const minutesString = minutes > 9 ? minutes : `0${minutes}`;
            const minuteText = minutes > 1 ? " minutes " : " minute ";

            const seconds = secondsToEnd % 60;
            const secondsString = seconds > 9 ? seconds : `0${seconds}`;
            const secondText = seconds > 1 ? " seconds " : " second ";

            if (hasHours) {
                return (
                    <>
                        <span className="dailyTimer__title">TIME REMAINING:</span>
                        <div className="clock">
                            <div>
                                <span className="clock__time">{hoursString}</span>
                                <span className="clock__text">{hourText}</span>
                            </div>
                            <div>
                                <span className="clock__time">{minutesString}</span>
                                <span className="clock__text">{minuteText}</span>
                            </div>
                            <div>
                                <span className="clock__time">{secondsString}</span>
                                <span className="clock__text">{secondText}</span>
                            </div>
                        </div>
                    </>
                );
            } else {
                return (
                      <>
                        <span className="dailyTimer__title">TIME REMAINING:</span>
                        <div className="clock">
                            <div>
                                <span className="clock__time">{minutesString}</span>
                                <span className="clock__text">{minuteText}</span>
                            </div>
                            <div>
                                <span className="clock__time">{secondsString}</span>
                                <span className="clock__text">{secondText}</span>
                            </div>
                        </div>
                      </>
                );
            }
        }
    }

    const timerDisplay = !hasTimerEnded ? timeString(time) : null;

    return timerDisplay;
};

export default Timer;
