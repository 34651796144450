﻿import React, { forwardRef, Ref, useEffect, useState } from "react";

import { getMoreDetails } from "../../../api/calls";

interface DailyDealsProps {
    productCode: string;
    auctionId: number | string;
    productImageURL: string;
    imageName: string;
    description: string;
    isOpen: boolean;
    zoomImage: (imageName) => void;
}

const DailyDealsDetails = forwardRef(
    (
        {
            productCode,
            auctionId,
            productImageURL,
            description,
            isOpen,
            zoomImage,
        }: DailyDealsProps,
        ref: Ref<HTMLDivElement>
    ) => {
        const [data, setData] = useState<APICore.AmberResponseProductDetails>();

        useEffect(() => {
            if (isOpen && !data) {
                getMoreDetails(productCode, auctionId).then((res) => {
                    setData(res);
                });
            }
        }, [isOpen]);

        const handleImageZoom = (imageName) => {
            zoomImage(imageName);
        };

        return (
            <>
                <div className="details" ref={ref}>
                    <div
                        className={`details__box${isOpen ? " details__box--active" : ""}`}
                    >
                        {data && (
                            <>
                                <div className="details__images">
                                    {data &&
                                        data.images &&
                                        data.images.map((image, index) => (
                                            <img
                                                className="details__singleImage"
                                                key={index}
                                                src={`${productImageURL}300/${image.filename}`}
                                                alt={`${description}`}
                                                onClick={() => handleImageZoom(`${image.filename}`)}
                                            />
                                        ))}
                                </div>
                                <div className="details__details">
                                    <div className="details__header">GEMSTONE DETAILS</div>
                                    <div className="details__row">
                                        <span>{data.gemstones![0].gemstone}</span>
                                        <span>
                                            {data.gemstones![0].carats
                                                ? data.gemstones![0].carats
                                                : "n/a"}
                                        </span>
                                    </div>
                                    <div className="details__row">
                                        <span>Design Code:</span>
                                        <span>{productCode}</span>
                                    </div>
                                    <div className="details__row">
                                        <span>Origin:</span>
                                        <span>
                                            {data.gemstones![0].origin
                                                ? data.gemstones![0].origin
                                                : "n/a"}
                                        </span>
                                    </div>
                                    <div className="details__row">
                                        <span>Clarity:</span>
                                        <span>
                                            {data.gemstones![0].clarity
                                                ? data.gemstones![0].clarity
                                                : "n/a"}
                                        </span>
                                    </div>
                                    <div className="details__row">
                                        <span>Cut:</span>
                                        <span>
                                            {data.gemstones![0].cut ? data.gemstones![0].cut : "n/a"}
                                        </span>
                                    </div>
                                    <div className="details__row">
                                        <span>Size:</span>
                                        <span>
                                            {data.gemstones![0].size
                                                ? data.gemstones![0].size
                                                : "n/a"}
                                        </span>
                                    </div>
                                    <div className="details__row">
                                        <span>Treatment:</span>
                                        <span>
                                            {data.gemstones![0].treatment
                                                ? data.gemstones![0].treatment
                                                : "n/a"}
                                        </span>
                                    </div>
                                </div>
                            </>
                        )}{" "}
                    </div>
                </div>
            </>
        );
    }
);

export default DailyDealsDetails;
