﻿import { setProductBid } from "../../../api/calls";
import { en } from "../../../language/en";
//const buttonText = _langID === "23" ? en.buttonText
const buttonText = en.buttonText;

const productText = en.productText;
/**
 * NOT FINISHED - CONTRIBUTIONS WELCOME
 * Button click function and boilerplate used by Gemporia auction webapps.
 *
 * @function handleClick
 *
 * @description See example boilerplate below.
 *
 * @external product - The product in question.
 * @external loggedIn - from initialState global.isLoggedIn
 * @external selected - from React local state
 * @external buyButtonRef - from React Element ref
 * @external dispatch - from React useReducer/redux
 * @external currentChannel - from React global state
 * @external logininUser - action from ./actions
 *
 * @example
 *
 *  // 'C2A button' state & global click
 *  const [selected, setSelected] = useState("");
 *  const buyButtonRef = useRef(null);
 *
    const handleClick = () => {
      // if no variation is selected then break here.
        if (product.hasVariation && !selected) {
                buyButtonRef.current.previousSibling.classList.add('variation--error');
                return false;
        }

        // check React isLoggedIn state. If not loggedIn show login modal -> to complete.
        if (loggedIn) {
            addToBasket({
                product: product,
                channelID: currentChannel.channelId,
                websiteID: currentChannel.websiteId,
                btns: buyButtonRef.current,
                selected: selected,
                setSelected: setSelected,
                isDemoProduct: !product.isCurrent && activeCategory.isRequestable;
            });
        } else {
            gemporiaAPP.common.login({
                onSuccess: () => {

                    // set React state isLoggedIn to be true
                    dispatch(loginUser(true));
                    addToBasket({
                      product: product,
                      channelID: currentChannel.channelId,
                      websiteID: currentChannel.websiteId,
                      btns: buyButtonRef.current,
                      selected: selected,
                      setSelected: setSelected,
                      isDemoProduct: !product.isCurrent && activeCategory.isRequestable;
                    });
                }
            });
        }
    }

 // import addToBasket from "addToBasket";
 */

export default function addToBasket(
    productDetailId,
    auctionId,
    btns,
    CGImageUrl,
    CGJobReference,
    subscriptionId,
    prodQty
) {
    const errorMessage = `${productText.basketError} ${productText.telNumber}`;
    if (CGImageUrl === undefined) {
        CGImageUrl = null;
    }
    if (CGJobReference === undefined) {
        CGJobReference = null;
    }

    // Set selectedProductDetailID if a variation is selected
    // Passing lead productDetailID on a product with variations will give a - 900 error
    const selectedProductDetailId = parseInt(productDetailId);

    const metaData = gemporiaAPP.common.helpers.addToBasketMetadata(
        btns.current.dataset.type
    );

    setProductBid(
        selectedProductDetailId,
        auctionId,
        CGImageUrl,
        CGJobReference,
        metaData,
        subscriptionId,
        prodQty
    ).then((response) => {
        if (response) {
            if (response.errorCode === 0) {
                // 'updateShownBasketQty' method defined in main.js
                gemporiaAPP.common.basket.updatenumber(response, false);

                const buttonOne = btns.current.children[0];
                buttonOne.innerText = buttonText.addedToBasket;
                setTimeout(() => {
                    if (btns.current.children.length === 2) {
                        // trigger the button slide
                        btns.current.classList.add("button--slide");
                        if (
                            btns.current.nextSibling.classList.contains("item__error--active")
                        ) {
                            btns.current.nextSibling.classList.remove("item__error--active");
                        }
                    }
                    setTimeout(() => {
                        buttonOne.innerText = buttonText.addToBasket;

                        if (btns.current.children.length === 2) {
                            btns.current.classList.remove("button--slide");
                        }
                    }, 5000);
                }, 1000);
            } else {
                btns.current.nextSibling.classList.add("item__error--active");
                btns.current.nextSibling.lastChild.innerHTML = errorMessage;
            }
        }
    });
}
