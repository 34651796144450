export const en = {
    "buttonText": {
        "addToBasket": "ADD TO BASKET",
        "addToPersonaliseBasket": "PERSONALISE & ADD",
        "addedToBasket": "ADDED TO BASKET",
        "proceedToCheckout": "PROCEED TO CHECKOUT",
        "requestDemo": "REQUEST A DEMO",
        "requestedDemo": "DEMO REQUESTED",
        "filter": "Filter",
        "sortBy": "Sort by",
        "searchPlaceholder": "SEARCH...",
        "tvSchedule": "TV SCHEDULE",
        "fullSchedule": "SEE FULL SCHEDULE",
        "meetThePresenter": "MEET THE PRESENTERS",
        "sendMessage": "SEND MESSAGE",
        "soldOut": "SOLD OUT"
    },
    "buttonTextUS": {
        "addToBasket": "ADD TO BASKET",
        "addToPersonaliseBasket": "PERSONALIZE & ADD",
        "addedToBasket": "ADDED TO BASKET",
        "proceedToCheckout": "PROCEED TO CHECKOUT",
        "requestDemo": "REQUEST A DEMO",
        "requestedDemo": "DEMO REQUESTED",
        "filter": "Filter",
        "sortBy": "Sort by",
        "searchPlaceholder": "SEARCH...",
        "tvSchedule": "TV SCHEDULE",
        "fullSchedule": "SEE FULL SCHEDULE",
        "meetThePresenter": "MEET THE PRESENTERS",
        "sendMessage": "SEND MESSAGE",
        "soldOut": "SOLD OUT"
    },
    "productText": {
        "timeLeft": "Time Left",
        "share": "SHARE",
        "selectSize": "Select Size",
        "soldOut": "SOLD OUT",
        "moreDetails": "MORE DETAILS",
        "designDetails": "DESIGN DETAILS",
        "gemstoneDetails": "GEMSTONE DETAILS",
        "designCode": "DESIGN CODE",
        "collection": "COLLECTION",
        "material": "MATERIAL",
        "jewelleryType": "JEWELLERY TYPE",
        "metalWeight": "AV. METAL WEIGHT",
        "numOfGems": "NO. OF GEMS",
        "caratWeight": "CARAT WEIGHT",
        "dimension": "DIMENSION",
        "qty": "QTY",
        "origin": "ORIGIN",
        "clarity": "CLARITY",
        "colour": "COLOUR",
        "cut": "CUT",
        "setting": "SETTING",
        "size": "SIZE",
        "treatment": "TREATMENT",
        "basketError": "Not adding? Please call",
        "variationError": "Please select a variation",
        "telNumber": "0333 400 0011"
    },
    "messageText": {
        "offAir": "We're not on-air at the moment!",
        "sendMessagePlaceholder": "SEND MESSAGE TO THE STUDIO (maximum 150 characters)",
        "sendMessageError": "Please enter a message and try again.",
        "sendMessageSuccess": "Thank you! Your message has been successfully sent."
    },
}
export default en;