﻿export const postBid = {
    url: `/api/webmethods/addtobasket?t=${Math.floor(Math.random() * 10000)}`,
    options: (auctionID, productDetailID, CGImageUrl, CGJobReference, metaData, subscriptionId, productQty) => ({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'productDetailID': productDetailID,
            'auctionID': auctionID,
            'basketID': -1,
            'CGImageUrl': CGImageUrl,
            'CGJobReference': CGJobReference,
            'metaData': metaData,
            'subOptionId': subscriptionId,
            'productQty': productQty
        }),
    })
};
