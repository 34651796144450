﻿import React, { forwardRef, useRef } from "react";
import mergeRefs from "react-merge-refs";

export interface ButtonProps {
    text: string;
    firstClick: () => void;
    secondaryText?: string;
    nextClick: () => void;
    style?: string;
    addToBasketEvent?: number;
    addToBasketURL?: string;
    type: string;
}

/**
 * NOT FINISHED - CONTRIBUTIONS WELCOME
 * Default Button used by Gemporia auction webapps
 * @callback firstClick
 * @callback nextClick
 * @function Button
 * @param {string} text
 * @param {firstClick} firstClick
 * @param {type} string
 * @param {string=} SecondaryText - Optional
 * @param {nextClick=} nextClick - Optional
 * @param {string=} [""] style - Optional
 *
 * @example
 * // Fake data renders fake button
 * const dummyButton = {
 * text: "ADD TO BASKET",
 * firstClick: addItemToBasket, // name of fn
 * secondaryText: "PROCCEED TO CHECKOUT",
 * nextClick: "proceedToCheckout",
 * style: "button--auction button--half"
 * };
 *
 * <Button ref={ref} props={...dummyButton} />
 */

const Button = forwardRef<HTMLDivElement, ButtonProps>((props, buttonRef) => {
    const {
        text,
        firstClick,
        secondaryText,
        nextClick,
        style,
        addToBasketEvent,
        addToBasketURL,
        type,
    } = props;

    const ref = useRef(null);

    if (addToBasketEvent === 3) {
        return (
            <div
                ref={mergeRefs([ref, buttonRef])}
                className={`button1 ${style ? style : ""}`}
                data-type={type}
            >
                <div
                    className="button__inner"
                    onClick={() => (window.location.href = `${addToBasketURL}`)}
                >
                    {text}
                </div>
            </div>
        );
    }

    return (
        <div
            ref={mergeRefs([ref, buttonRef])}
            className={`button ${style ? style : ""}`}
            tabIndex={0}
            role="button"
            aria-pressed="false"
            data-type={type}
        >
            <div className="button__inner" onClick={() => firstClick()}>
                {text}
            </div>
            {secondaryText && nextClick && (
                <div className="button__inner" onClick={() => nextClick()}>
                    {secondaryText}
                </div>
            )}
        </div>
    );
});

Button.displayName = "Button";

export default Button;
