﻿import React, { useRef, useState, useEffect, useCallback } from "react";
import Button from "../Button";
import { Lightbox } from "react-modal-image";
import addToBasket from "../../../pages/dailyDeals/helpers/addToBasket";
import { en } from "../../../language/en";
import DailyDealsDetails from "../../../pages/dailyDeals/components/DailyDealsDetails";

interface ItemProps {
    description: string;
    price: string;
    webPrice: string;
    saving: number | string;
    imageName: string;
    productImageURL: string;
    productDetailId: number | string;
    auctionId: number | string;
    productCode: string;
    soldOut: boolean;
}

const Item = (props: ItemProps) => {
    const {
        description,
        price,
        webPrice,
        saving,
        imageName,
        productImageURL,
        productDetailId,
        auctionId,
        productCode,
        soldOut,
    } = props;

    const btnRef = useRef(null);
    const moreRef = useRef<HTMLDivElement>(null);
    const detailsRef = useRef<HTMLDivElement>(null);
    const ligthBoxRef = useRef<HTMLDivElement>(null);

    const [isOpen, setIsOpen] = useState(false);
    const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
    const [updatedName, setUpdatedName] = useState(imageName);

    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", clickAway);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", clickAway);
        };
    }, []);

    let btnTextSecondary = en.buttonText.proceedToCheckout;

    const handleFirstClick = () => {
        addToBasket(productDetailId, auctionId, btnRef, null, null, null, 1);
    };

    const handleSecondClick = () => {
        gemporiaAPP.common.basket.gotoSecureWebsite("basket");
    };

    const toggleDetails = useCallback(() => {
        setIsOpen(prevState => !prevState);
    }, [isOpen]);

    const clickAway = (e) => {
        // Alert if clicked on outside of element
        if (moreRef && moreRef.current && !moreRef.current.contains(e.target)) {
            if (
                detailsRef &&
                detailsRef.current &&
                detailsRef.current.contains(e.target)
            ) {
                return null;
            }
            // If the click is on the scroll bar, close modal icon or modal overlay then ignore the click.

            if (
                e.target.nodeName === "HTML" ||
                Boolean(e.target.closest(".__react_modal_image__modal_content")) ||
                Boolean(e.target.closest(".__react_modal_image__icon_menu")) ||
                Boolean(e.target.closest(".__react_modal_image__header"))
            ) {
                return null;
            }

            setIsOpen(false);
        }
    };

    const handleImageZoom = (imageName) => {
        setUpdatedName(imageName);
        setIsLightBoxOpen(true);
    };

    const closeLightbox = () => {
        setIsLightBoxOpen(false);
    };
    const openLightBox = () => {
        setUpdatedName(imageName);
        setIsLightBoxOpen(true);
    };

    return (
        <div className="item__container">
            <div className="item__wrapper">
                <div className="item__save">Save {saving}%</div>

                <img
                    src={`${productImageURL}300/${imageName}`}
                    onClick={openLightBox}
                    alt={`${description}`}
                    className="item__image"
                />

                {isLightBoxOpen && (
                    <Lightbox
                        small={`${productImageURL}300/${updatedName}`}
                        medium={`${productImageURL}700/${updatedName}`}
                        large={`${productImageURL}1200/${updatedName}`}
                        hideDownload={true}
                        alt={`${description}`}
                        onClose={closeLightbox}
                        ref={ligthBoxRef}
                    />
                )}
            </div>
            <h3 className="item__description">{description}</h3>
            <div className="item__webPrice">
                {soldOut ? " " : "Was: "}
                <span>{soldOut ? " " : webPrice}</span>
            </div>
            <div className="item__price">{soldOut ? "SOLD OUT" : price}</div>
            <Button
                ref={btnRef}
                text="ADD TO BASKET"
                firstClick={handleFirstClick}
                nextClick={handleSecondClick}
                secondaryText={btnTextSecondary}
                style={`item__button${soldOut ? " item__button--sold" : ""}`}
                type={`dd-standard`}
            />
            <div className="item__error">
                <i className="fa fa-info"></i>
                <p className="item__errorMessage"></p>
            </div>
            <div className="item__more" onClick={() => toggleDetails()} ref={moreRef}>
                <span
                    className={`item__caret${isOpen ? " item__caret--active" : ""}`}
                ></span>
                <div className="item__text">MORE DETAILS</div>
            </div>
            <DailyDealsDetails
                isOpen={isOpen}
                productCode={productCode}
                auctionId={auctionId}
                productImageURL={productImageURL}
                imageName={imageName}
                description={description}
                ref={detailsRef}
                zoomImage={(imageName) => handleImageZoom(imageName)}
            />
        </div>
    );
};

export default Item;
