﻿import React from 'react';

interface BoldTextProps extends React.HTMLAttributes<HTMLSpanElement> {
    text: string;
    shouldBeBold: string;
}

function BoldedText({ text, shouldBeBold, ...rest }: BoldTextProps) {
    const textArray = text.split(RegExp(shouldBeBold, 'ig'));
    const match = text.match(RegExp(shouldBeBold, 'ig'));

    return (
        <div {...rest} role='option'>
            <span >
                {textArray.map((item, index) => (
                    <React.Fragment key={index}>
                        {item}
                        {index !== textArray.length - 1 && match && <strong>{match[index]}</strong>}
                    </React.Fragment>
                ))}
            </span>
        </div>
    );
}

export default BoldedText;
