﻿import { global } from "../constants/globals";
import { postBid } from "./endpoints";

var _productsClient = new APICore.ProductsAPIClient(global.apiBaseURL);
const _dailyDealsScheduleClient = new APICore.AuctionsAPIClient(
    global.apiBaseURL
);
const _previousAuctionsClient = new APICore.AuctionsAPIClient(
    global.apiBaseURL
);

const { currencyCulture, languageId, websiteId, currencyId, delCountryId, channelId } = global;

// fetchF will return an error first thenable promise, in which we can dispatch our state change
export function fetchF(err, url, options) {
    const responseJSON = (res) => {
        if (res.status >= 200 && res.status < 300) {
            return res.json();
        } else {
            throw new Error(
                `Api call was not successful received a ${res.status} error`
            );
        }
    };
    return fetch(url, options)
        .catch((e) => console.error(err, e))
        .then(responseJSON);
}

export function getMoreDetails(productCode, auctionId) {
    return _productsClient
        .productDetails(languageId, productCode, auctionId, websiteId)
        .then((json) => {
            if (json.errorCode == 0) {
                return json.data;
            }
        });
}

export function getSchedule(clearCache) {
    return _dailyDealsScheduleClient
        .dailyDealsSchedule(
            languageId,
            currencyId,
            delCountryId,
            clearCache,
            channelId,
            websiteId
        )
        .then((json) => {
            if (json.errorCode == 0) {
                return json.data;
            }
        });
}

export function getResponseUpdate() {
    return _previousAuctionsClient
        .moreAuctionsSectionsV2(
            currencyCulture,
            languageId,
            currencyId,
            delCountryId,
            APICore.ChannelId.GemCollectorDailyDeals,
            "",
            "",
            "",
            "",
            "",
            "",
            APICore.ProductSortOrder.Default,
            1,
            99999,
            global.websiteId
        )
        .then((json) => {
            if (json.errorCode == 0) {
                return json.data;
            }
        });
}

export function setProductBid(
    productID,
    auctionID,
    CGImageUrl,
    CGJobReference,
    metaData,
    subscriptionId,
    productQty
) {
    const { url, options } = postBid;
    const code = "Add to Basket";
    return fetchF(
        code,
        url,
        options(auctionID, productID, CGImageUrl, CGJobReference, metaData, subscriptionId, productQty)
    );
}
